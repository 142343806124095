:root {
  --black: #1A73E8;
  --grey: #ffffff;
  --white: #818080;
}

span.switcher {
  position: relative;
  width: 180px;
  height: 60px;
  border-radius: 25px;
  margin: 0px 0;
}

span.switcher input {
  appearance: none;
  position: relative;
  width: 170px;
  height: 60px;
  border-radius: 25px;
  background-color: var(--black);
  outline: none;
  font-family: 'Oswald', sans-serif;
}

span.switcher input:before, 
span.switcher input:after {
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--white);
}

span.switcher input:before {
  content: 'Monthly';
  left: 10px;
}

span.switcher input:after {
  content: 'Yearly';
  right: 10px;
}

span.switcher label {
  z-index: 1;
  position: absolute;
  top: 10px;
  bottom: 10px;
  border-radius: 20px;
}

span.switcher.switcher-1 input {
  transition: .25s -.1s;
}

span.switcher.switcher-1 input:checked {
  background-color: var(--white);
}

span.switcher.switcher-1 input:checked:before {
  color: var(--grey);
  transition: color .5s .2s;
}

span.switcher.switcher-1 input:checked:after {
  color: var(--grey);
  transition: color .5s;
}

span.switcher.switcher-1 input:checked + label {
  left: 10px;
  right: 100px;
  background: var(--black);
  transition: left .5s, right .4s .2s;
}

span.switcher.switcher-1 input:not(:checked) {
  background: var(--black);
  transition: background .5s -.1s;
}

span.switcher.switcher-1 input:not(:checked):before {
  color: var(--grey);
  transition: color .5s;
}

span.switcher.switcher-1 input:not(:checked):after {
  color: var(--grey);
  transition: color .5s .2s;
}

span.switcher.switcher-1 input:not(:checked) + label {
  left: 100px;
  right: 10px;
  background: var(--white);
  transition: left .4s .2s, right .5s, background .35s -.1s;
}

@keyframes turn-on {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}


@keyframes turn-off {
  0% {
    right: 100%;
  }
  100% {
    right: 0%;
  }
}
