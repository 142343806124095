  .icon {
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
  }

  .icon:hover:after {
    transform: scale(2.45);
    opacity: 0.8;
  }
  
  .pulsing:before {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    content: '';
    box-shadow: inset 0 0 0 3px #344767;
    transition: transform 0.2s, opacity 0.2s;
    animation: pulsing 2.7s infinite;
  }

  .pulsing1:before {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    content: '';
    box-shadow: inset 0 0 0 3px #f50202;
    transition: transform 0.2s, opacity 0.2s;
    animation: pulsing1 2.7s infinite;
    -webkit-animation: pulsing1 2.7s infinite;
  }

  .pulsing2:before {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    content: '';
    box-shadow: inset 0 0 0 3px #ffffff;
    transition: transform 0.2s, opacity 0.2s;
    animation: pulsing2 2.7s infinite;
    -webkit-animation: pulsing2 2.7s infinite;
  }

  @keyframes pulsing {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(2);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  
  @keyframes pulsing1 {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(2);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes pulsing2 {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(2);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  
  @media (min-width:200px) {
    .pulsing:before {
      position: absolute;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      top: 10px;
      content: '';
      box-shadow: inset 0 0 0 3px #344767;
      transition: transform 0.2s, opacity 0.2s;
      animation: pulsing 2.7s infinite;
    }
  }
   
  @media (min-width:500px) {
    .pulsing:before {
      position: absolute;
      width: 25px;
      height: 25px;
      top: 5px;
      border-radius: 50%;
      content: '';
      box-shadow: inset 0 0 0 3px #344767;
      transition: transform 0.2s, opacity 0.2s;
      animation: pulsing 2.7s infinite;
    }
  }
   
  @media (min-width:800px) {
    .pulsing:before {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 1px;
      border-radius: 50%;
      content: '';
      box-shadow: inset 0 0 0 3px #344767;
      transition: transform 0.2s, opacity 0.2s;
      animation: pulsing 2.7s infinite;
    }
  }

  @media (min-width:200px) {
    .pulsing1:before {
      position: absolute;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      top: 10px;
      content: '';
      box-shadow: inset 0 0 0 3px #f50202;
      transition: transform 0.2s, opacity 0.2s;
      animation: pulsing1 2.7s infinite;
    }
  }
   
  @media (min-width:500px) {
    .pulsing1:before {
      position: absolute;
      width: 25px;
      height: 25px;
      top: 5px;
      border-radius: 50%;
      content: '';
      box-shadow: inset 0 0 0 3px #f50202;
      transition: transform 0.2s, opacity 0.2s;
      animation: pulsing1 2.7s infinite;
    }
  }
   
  @media (min-width:800px) {
    .pulsing1:before {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 1px;
      border-radius: 50%;
      content: '';
      box-shadow: inset 0 0 0 3px #f50202;
      transition: transform 0.2s, opacity 0.2s;
      animation: pulsing1 2.7s infinite;
    }
  }

  @media (min-width:200px) {
    .pulsing2:before {
      position: absolute;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      top: 10px;
      content: '';
      box-shadow: inset 0 0 0 3px #ffffff;
      transition: transform 0.2s, opacity 0.2s;
      animation: pulsing2 2.7s infinite;
    }
  }
   
  @media (min-width:500px) {
    .pulsing2:before {
      position: absolute;
      width: 25px;
      height: 25px;
      top: 5px;
      border-radius: 50%;
      content: '';
      box-shadow: inset 0 0 0 3px #ffffff;
      transition: transform 0.2s, opacity 0.2s;
      animation: pulsing2 2.7s infinite;
    }
  }
   
  @media (min-width:800px) {
    .pulsing2:before {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 1px;
      border-radius: 50%;
      content: '';
      box-shadow: inset 0 0 0 3px #ffffff;
      transition: transform 0.2s, opacity 0.2s;
      animation: pulsing2 2.7s infinite;
    }
  }
